<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose">
  <!-- <el-drawer
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="demo-drawer"
    :before-close="handleClose"
    ref="drawer"
  > -->
    <el-form
      :model="saveUpdateFrom"
      label-width="100px"
      ref="saveUpdateFrom"
      :rules="rules"
    >
      <el-row :gutter="24">
        <el-form-item label="名称" prop="name">
          <el-input v-model="saveUpdateFrom.name" disabled />
        </el-form-item>
      </el-row>
      <el-row :gutter="24">
        <el-form-item label="货币代码" prop="code">
          <el-input v-model="saveUpdateFrom.code" disabled />
        </el-form-item>
      </el-row>
      <el-row :gutter="24">
        <el-form-item label="货币符号" prop="symbol">
          <el-input v-model="saveUpdateFrom.symbol" />
        </el-form-item>
      </el-row>
      <el-row :gutter="24">
        <el-form-item label="汇率" prop="exchangeRate">
          <el-input
            v-model="saveUpdateFrom.exchangeRate"
            @change="exchangeRateButton()"
          />
        </el-form-item>
      </el-row>
      <!-- <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item
            label="0表示非标准币，1表示标准币,暂时未使用"
            prop="type"
          >
            <el-input v-model="saveUpdateFrom.type" />
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-row :gutter="24">
        <el-form-item label="描述" prop="description">
          <el-input v-model="saveUpdateFrom.description" />
        </el-form-item>
      </el-row>

      <el-row :gutter="24">
        <el-form-item label="备注" prop="remark">
          <el-input v-model="saveUpdateFrom.remark" />
        </el-form-item>
      </el-row>
      <el-row :gutter="24">
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="saveUpdateFrom.status">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">失效</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="demo-drawer__footer" style="margin: 0px 50px">
      <el-button type="primary" @click="editSaveButton">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
    <!-- </el-dialog> -->
  </el-dialog>
</template>
  <script>
import format from "@/plugins/format.js";
import {
  getCommonCurrencySelectByIdApi,
  editCommonCurrencyDataApi,
} from "@/api/system/currency/currency.js";
export default {
  data() {
    return {
      dialogVisible: false,
      ids: null,
      types: 1,
      editShow: true,
      title: null,
      saveUpdateFrom: {},
      // 表单校验
      rules: {},
      oldExchangeRate: null,
    
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    show(row, type) {
      this.initData();
      this.dialogVisible = true;
      this.types = type;
      if (type == 1) {
        this.title = "新增";
      }
      if (type == 2) {
        this.title = "编辑";
        this.ids = row.id;
        this.selectDataById();
      }
    },
    selectDataById() {
      getCommonCurrencySelectByIdApi(this.ids).then((res) => {
        this.saveUpdateFrom = res.result;
        this.oldExchangeRate = res.result.exchangeRate;
      });
    },
    cancel() {
      this.saveUpdateFrom = {};
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.selectListButton();
      this.ids = null;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
      this.$refs["saveUpdateFrom"].resetFields();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.cancel();
        })
        .catch((_) => {});
    },
    editSaveButton() {
      this.$refs["saveUpdateFrom"].validate((valid) => {
        if (valid) {
          if (this.types == 1) {
            //新增成功
          } else {
            editCommonCurrencyDataApi(this.saveUpdateFrom).then((res) => {
              this.cancel();
              this.$parent.selectListButton();
            });
            //修改成功
          }
        }
      });
    },
    initData() {},
    exchangeRateButton(vale) {
      this.saveUpdateFrom.exchangeRateTime = format(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      );
    },
  },
};
</script>
  <style lang="less" scoped>
</style>