
import { getAction, getByIdAction, postAction, putAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonCurrency/v1'
//调用 import { getTableLists } from "@/api/reportFrom/repost";

const getCommonCurrencyPageListApi = (params) => getAction(baseURL + '/pageList', params);

const getCommonCurrencListApi = (params) => getAction(baseURL + '/list', params);

const getCommonCurrencySelectByIdApi = (params) => getByIdAction(baseURL + '/selectById', params);

const addCommonCurrencyDataApi = (params) => postAction(baseURL + '/add', params);

const editCommonCurrencyDataApi = (params) => putAction(baseURL + '/edit', params);

const deleteCommonCurrencyByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);
export {
    getCommonCurrencyPageListApi,
    getCommonCurrencySelectByIdApi,
    addCommonCurrencyDataApi,
    editCommonCurrencyDataApi,
    deleteCommonCurrencyByIdApi,
    getCommonCurrencListApi
}